<template>
  <div class="h-100 w-100 d-flex"> 

    <div class="d-flex flex-column h-100 w-100 p-1">
      <!-- Options top -->
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
           <span><feather-icon icon="MenuIcon"/></span>
        </b-button>
        <div class="d-flex">
  
          <b-button-group class="ml-2 body-button-center max-width-disabled">
            <b-button
            :class="`${!grid_size_active ? 'active-button-center' : 'color-btn-center'} size-button-center`"
            @click="grid_size_active =  false"
            >
              <b-icon icon="grid3x3-gap-fill" font-scale="1.5" variant="dark"></b-icon>
            </b-button>
            <b-button
            :class="`${grid_size_active ? 'active-button-center' : 'color-btn-center'} size-button-center`"
            @click="grid_size_active = true"
            >
              <b-icon icon="grid-fill" font-scale="1.5" variant="dark"></b-icon>
            </b-button>
          </b-button-group>
  
          <b-dropdown
            variant="outline-secondary" 
            class="m-5rem size-drop-responsive"
            no-caret
            right
          > 
            <template #button-content><b-icon icon="three-dots-vertical" font-scale="1.3" variant="dark"/></template> 
            <b-dropdown-item @click="grid_size_active = !grid_size_active">
              <div class="d-flex justify-content-between">
                <span class="mr-2">{{$t('search.gridSize')}}</span>
                <b-icon :icon="grid_size_active ? 'grid-fill' : 'grid3x3-gap-fill'" font-scale="1" variant="dark"/>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item disabled>
              <div class="d-flex justify-content-between">
                <span>{{$t('tracking.export')}}</span>
                <span><feather-icon icon="ShareIcon"/></span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
  
          <b-button 
            variant="outline-secondary" 
            class="size-button-center m-5rem icon-setting-sider"
            @click="sider_option_ai = !sider_option_ai"
          >
            <b-icon icon="gear-wide-connected" font-scale="1.3" variant="dark"></b-icon>
          </b-button>
        </div>
      </div>
      <!----------------->
      <div class="flex-grow">
        <b-row v-if="loader_images_history">
          <div 
            v-if="request_history.status !== 'error'" 
            :class="`${getGrid()} grid-container m-0 w-100 aspect-one aspect-ratio-1-1 p-1`" 
            v-show="loader_images_history"
            >
            <div
              v-for="(data , index) in data_img_ai.length > 0 ? data_img_ai : setting_selected.number_image_ai"
              class="aspect-container-ratio position-relative "
              :key="index"
              :ref="`aspect_ratio_box_${index}`"
            >           
            <div class="h-100 aspect-ratio" @click="data_img_ai.length > 0 ? gridColSpan(index) : ''">
                <img v-if="data_img_ai.length === 0" :src="require('@/assets/images/svg/stars.svg')" class="img-stars"/>
                <div v-else-if="data_img_ai.length > 0 && !loader_image_search" class="w-100 h-100 position-relative">
                  <img :src="data.url" class="img-ia"/>
                  <div class="container-ia-svg">
                    <img :src="require('@/assets/images/icons/ia.svg')" :alt="`ia-svgd`" class="ia-svg-icon">
                  </div>
                </div>
                <b-skeleton class="skeleton-loading-ia" v-if="loader_image_search"></b-skeleton>
              </div>
              <div class="container-dropdown-image"  @click="downloadFile(data.url)" v-if="data.url">                    
                <feather-icon icon="DownloadIcon"></feather-icon>
              </div>
            </div>
          </div>
          <div :class="`${getGrid()} grid-container m-0 w-100`" v-else>
            <div
              v-for="(data , index) in setting_selected.number_image_ai"
              class="aspect-container-ratio"
              :key="index"
              :ref="`aspect_ratio_box_${index}`"
            >           
              <b-img  :src="require('@/assets/images/svg/broken-image.svg')" class="image-error w-100 h-100"></b-img>
            </div>
          </div>
        </b-row>
      </div>
  
      <!-- Container search input -->
      <div class="background-input-botton" v-if="Object.keys(history_images).length === 0">
        <div class="d-flex justify-content-between align-items-center div-input-button">
          <b-form-input 
            :placeholder="placeholder_text" 
            class="size-input" 
            v-model="setting_selected.promp_ai" 
            autocomplete="off" 
            ref="textInput" 
            @focus="onFocusInputSearch"
            @blur="onBlurInputSearch"
          />
          <b-button
          :disabled="setting_selected.promp_ai === null || setting_selected.promp_ai.length < 3"
          class="generate-img-ai"
          @click="generateImage()"
          v-if="!loader_image_search"
          >
          {{ data_img_ai.length > 0 ? $t('search.regenerateImg') : $t('search.generateImg')}}
          <b-badge class="badge-button-ia"><feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>{{ getCredits() >= 0 ? getCredits() : $t('ia.general.insufficientCredits') }}</b-badge>
          </b-button>
          <b-skeleton v-else type="button" class="generate-img-ai"></b-skeleton>
        </div>
      </div>
      <div v-else class="d-flex justify-content-between border-top pt-2">
        <div>
          <b-button variant="outline-secondary" @click="backToHistory()">{{ $t('ia.general.back') }}</b-button>
        </div>
        <div>
          <b-button variant="outline-secondary" @click="$emit('change_uuid', 'backward')">
            <feather-icon icon="ChevronLeftIcon"></feather-icon>
          </b-button>
          <b-button variant="outline-secondary" class="ml-1" @click="$emit('change_uuid', 'forward')">
            <feather-icon icon="ChevronRightIcon"></feather-icon>
          </b-button>
        </div>
      </div>
      <!-- -------------------------- -->
    </div>

        <!-- Options AI -->
    <div class="width-right-area">
      <sider-right-options 
        class="w-100" 
        ref="sider_data" 
        :loader_image_search="loader_image_search" 
        :setting_selected="setting_selected" 
        :prompt_builder="prompt_builder"
        :options_imagine="options_imagine"
        :key="update_sidebar"
        v-if="Object.keys(history_images).length === 0"
      />
      <sider-right-history
        class="w-100" 
        :history_images="history_images"
        @edit_history="$emit('edit_history')"
        v-else
      ></sider-right-history>
    </div>
    <!-- ---------- --> 
      <!-- Responsive sider-right options -->
    <b-sidebar v-model="sider_option_ai" backdrop shadow right class="sidebar-right-responsive-options-models-ia"> 
      <sider-right-options 
        v-if="Object.keys(history_images).length === 0" 
        ref="sider_data" 
        :loader_image_search="loader_image_search" 
        :setting_selected="setting_selected" 
        :is_sidebar="true" 
        :prompt_builder="prompt_builder"
        :options_imagine="options_imagine"
      />
      <sider-right-history
        class="w-100" 
        :history_images="history_images"
        @edit_history="$emit('edit_history')"
        v-else
      ></sider-right-history>
    </b-sidebar>
    <!-- ------------------------------ -->

    <modal-brandme :show_modal="show_modal_not_friendly" :no_footer="true" :key="update_modal_not_friendly">
      <template #title>
        <h5 class="text-center w-100">{{ $t('ia.image.promptBlocked') }}</h5>
      </template>

      <template #body>
        <div class="d-flex flex-column align-items-center justify-content-center pb-1">
          <p class="avenir-medium text-center">{{ $t('ia.image.promptContains') }} <b-link target="_blank" href="https://brandme.la/legal/"> {{ $t('ia.image.termsOfService') }}</b-link>. </p>
          <b-button variant="blue-button" class="blue-button" @click="show_modal_not_friendly = false; update_modal_not_friendly = !update_modal_not_friendly">{{ $t('campaigns.close') }}</b-button>
        </div>
      </template>
    </modal-brandme>
  </div>
</template>
  
<script>
  
import {
  BRow,
  BDropdown,
  BDropdownItem,
  BSkeleton,
  BButton,
  BButtonGroup,
  BDropdownDivider,
  BFormInput,
  BSidebar,
  BBadge,
  BImg,
  BLink
} from 'bootstrap-vue';
import { createImage, getDetailHistory, createVariationsImage, getPriceActions } from '@/services/ia'
import { getModelsImagine, getArtStyleImagine, downloadFile } from '@/libs/utils/others'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

  
export default {
  name: 'containerGrid',
  components: {
    BRow,
    BDropdown,
    BDropdownItem,
    BSkeleton,
    BButton,
    BButtonGroup,
    BDropdownDivider,
    BFormInput,
    BLink,
    BSidebar,
    BBadge,
    BImg,
    siderRightOptions: () => import('./siderbars/siderRightOptions.vue'),
    siderRightHistory: () => import('./siderbars/siderRightHistory.vue'),
    ModalBrandme: () => import('../modal/ModalBrandme.vue'),
  },
  props: {
    history_images: {
      type: Object,
      default: () => {
        return {}
      },
    },
    credits_avaible: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      downloadFile,
      placeholder_text: '',
      index_ratio_selected: null,
      grid_size_active: true,
      data_img_ai: [],

      prompt_builder: {
        camera: {
          models: {
            hardware: "",
            lens: "",
            aperture: "",
            framing: ""
          }
        },
        art_style: {
          models: {
            primary_style: "",
            secondary_style: "",
            perspective: "",
            medium: "",
            substrate: ""
          }
        },
        artist: {
          models: {
            genre: "",
            style_painter: ""
          }
        },
        scene: {
          models: {
            palette: "",
            lighting: "",
            aesthetic: "",
            emotion: "",
            atmosphere: "",
            background: ""
          }
        },
        technical: {
          models: {
            resolution: "",
            renderer: "",
            website: ""
          }
        },
        modifiers: {
          models: {
            concepts: "",
            thematics: "",
            hallucinations: "",
            distortions: ""
          }
        }
      },
      setting_selected: {
        promp_ai: null,
        ia_selected: this.$t('search.ia_options')[0],
        number_image_ai: 1,
        negative_prompt: undefined,
        size_images_selected: this.$t('search.size_images')[0],
        file_variants: null,
      },
      options_imagine: {
        model: {value: 33, category: 'imagine_v5', name: 'Imagine V5'},
        aspect_ratio: '1:1',
        art_style: null,
        cfg: 3,
        steps: 30,
        seed: null,
        high_res_results: 0,
      },
      image_active_slide: false,
      loader_image_search: false,
      sider_option_ai: false,
      update_sidebar: false,
      loader_images_history: false,
      request_history: {},
      is_focus: false,
      price_images: {},
      show_modal_not_friendly: false,
      update_modal_not_friendly: false,
    }
  },
  created() {
    if (Object.keys(this.history_images).length > 0) this.data_img_ai = this.history_images.response_server.data;

    if (this.$route.params.uuid) {
      this.getDetailHistoryImages()
    } else {
      this.loader_images_history = true
    }

    this.getPriceImage();
    this.$root.$on('images_created_ws_or_variant', (data) => {
      this.request_history = data.body;
      this.data_img_ai = Array.isArray(data.body.response_server.data) ? data.body.response_server.data : [];
      this.loader_image_search = false;
      this.loader_images_history = true;
      if (data.body.status === 'complete') {
        this.$emit('update_credits', this.getCredits())
        this.$root.$emit('update_credits_drop', this.getCredits())
      }
    });
  },
  mounted() {
    this.startAnimation();
  },
  methods: {
    getPriceImage() {
      getPriceActions().then((response) => {
        this.price_images = response;
      })
    },
    async typeWriter(text, index, speed) {
      if (index < text.length && !this.is_focus) {
        this.placeholder_text += text.charAt(index);
        await this.sleep(speed);
        await this.typeWriter(text, index + 1, speed);
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async animatePlaceholder(text, speed) {
      this.placeholder_text = '';
      await this.typeWriter(text, 0, speed);
    },
    startAnimation() {
      const placeholder_text = this.$t('search.describeImage');
      const speed = 50;
      this.animatePlaceholder(placeholder_text, speed);
    },
    onFocusInputSearch() {
      this.is_focus = true;
      this.placeholder_text = '';
    },
    onBlurInputSearch() {
      this.is_focus = false;
      this.startAnimation();
    },
    getDetailHistoryImages() {
      getDetailHistory(this.$route.params.uuid).then((response) => {
        this.request_history = response;
        this.loader_images_history = true;
        this.data_img_ai = response.response_server.data;
        this.setting_selected.promp_ai = response.original_prompt;
        this.setting_selected.number_image_ai = response.num_images;
        this.setting_selected.negative_prompt = response.data.negative_prompt;
        this.setting_selected.file_variants = response.data.origin_image;
        this.update_sidebar = !this.update_sidebar

        setTimeout(() => {
          this.setting_selected.size_images_selected = this.splitSizeString(response.size_images, response.data.model)
        }, 200);
        if (response.data.model) this.setting_selected.ia_selected = this.$t('search.ia_options').find((ia_op) => ia_op.value === response.data.model)
        if (response.type_request !== 'variant_image' && response.data.model !== 'imagine') this.formatPrompt(response.data);
        else if (response.type_request !== 'variant_image' && response.data.model === 'imagine') this.formatPromptImagine(response.data)
        else if (response.type_request === 'variant_image') this.formatPromptVariantImage(response)
      })
    },
    formatPrompt(data) {
      for (const value in data) {
        const evit = ['negative_prompt', 'prompt', 'model']
        if (data[value] && evit.indexOf(value) === -1) {
          const skeleton = this.$t('search.prompts_skeleton')[value]
          this.prompt_builder[skeleton[0]].models[skeleton[1]] = data[value];
        }
      }
    },
    formatPromptImagine(data) {
      this.options_imagine.model = getModelsImagine().find((i) => i.value === data.style_id);
      setTimeout(() => {
        this.options_imagine.aspect_ratio = data.aspect_ratio;
      }, 5);
      this.options_imagine.art_style = data.art_style ? getArtStyleImagine().find((i) => i.name === data.art_style) : null;
      this.options_imagine.high_res_results = data.high_res_results === 1;
      this.options_imagine.cfg = data.cfg;
      this.options_imagine.steps = data.steps;
      this.options_imagine.seed = data.seed;
    },
    formatPromptVariantImage(data) {
      setTimeout(() => {
        if (Object.keys(this.history_images).length === 0) this.$refs.sider_data.tabs_options = 1;
      }, 200);
      this.setting_selected.size_images_selected = (data.data.model === 'dall-e') ? this.$t('search.size_images').find((i) => i.value_width === data.data.width) : this.$t('search.size_images_xl').find((i) => i.value_width === data.data.width)
    },
    splitSizeString(size, model) {
      const splitted_size =  parseInt(size.split('x')[0]);
      if (model !== 'dall-e') return this.$t('search.size_images_xl').find((size) =>  size.value_width === splitted_size)
      else return this.$t('search.size_images').find((size) =>  size.value_width === splitted_size)
    },
    getGrid() {
      if (this.setting_selected.number_image_ai === 1) {
        return 'grid-one'
      } else if (this.image_active_slide && this.grid_size_active) {
        return 'grid-active-left'
      } else if (!this.image_active_slide && this.grid_size_active) {
        return 'grid-desactive-left'
      } else if (!this.grid_size_active) {
        return 'grid-long-left'
      }
    },
    getCredits() {
      const credits_map = {256: 1, 512: 2, 1024: 3};
      const { ia_selected, size_images_selected, number_image_ai } = this.setting_selected;
      const credit = ia_selected.value === 'dall-e' ? credits_map[size_images_selected.value_width] : ia_selected.value === 'dall-e-3' ? this.price_images.images_dall_e_3 : 10;
      return this.credits_avaible >= credit * number_image_ai ? credit * number_image_ai : -1;
    },
    getDataForImagineModel() {
      const data = {
        model: this.setting_selected.ia_selected.value,
        prompt: this.setting_selected.promp_ai,
        number: 1,
        negative_prompt: this.setting_selected.negative_prompt,
        aspect_ratio: this.options_imagine.aspect_ratio,
        style_id: this.options_imagine.model.value,
        art_style: this.options_imagine.art_style ? this.options_imagine.art_style.name : null,
        cfg: this.options_imagine.cfg,
        steps: this.options_imagine.model.category.includes('imagine_v4') ? null : this.options_imagine.steps,
        seed: this.options_imagine.seed,
        high_res_results: this.options_imagine.high_res_results ? 1 : 0,
      }
      return data
    },
    generateImage() {
      
      if (this.getCredits() >= 0 && !this.setting_selected.file_variants) {
        this.loader_image_search = true;

        // Get data for rests of models or imagine model
        const data = this.setting_selected.ia_selected.value !== 'imagine' ? {
          model: this.setting_selected.ia_selected.value,
          prompt: this.setting_selected.promp_ai,
          number: this.setting_selected.number_image_ai,
          width: this.setting_selected.size_images_selected.value_width,
          height: this.setting_selected.size_images_selected.value_height,
          negative_prompt: this.setting_selected.negative_prompt,
          hardware_camera: this.prompt_builder.camera.models.hardware,
          lens_camera: this.prompt_builder.camera.models.lens,
          aperture_camera: this.prompt_builder.camera.models.aperture,
          framing_camera: this.prompt_builder.camera.models.framing,
          primary_style: this.prompt_builder.art_style.models.primary_style,
          secondary_style: this.prompt_builder.art_style.models.secondary_style,
          perspective: this.prompt_builder.art_style.models.perspective,
          medium: this.prompt_builder.art_style.models.medium,
          substrate: this.prompt_builder.art_style.models.substrate,
          artist_genre: this.prompt_builder.artist.models.genre,
          artist_style: this.prompt_builder.artist.models.style_painter,
          scene_palette: this.prompt_builder.scene.models.palette,
          scene_lighting: this.prompt_builder.scene.models.lighting,
          scene_aesthetic: this.prompt_builder.scene.models.aesthetic,
          scene_emotion: this.prompt_builder.scene.models.emotion,
          scene_atmosphere: this.prompt_builder.scene.models.atmosphere,
          scene_background: this.prompt_builder.scene.models.background,
          technical_resolution: this.prompt_builder.technical.models.resolution,
          techinical_renderer: this.prompt_builder.technical.models.renderer,
          technical_website: this.prompt_builder.technical.models.website,
          concept: this.prompt_builder.modifiers.models.concepts,
          thematics: this.prompt_builder.modifiers.models.thematics,
          hallucinations: this.prompt_builder.modifiers.models.hallucinations,
          distorcions: this.prompt_builder.modifiers.models.distortions
        } : this.getDataForImagineModel();

        const formated_data = {}
        for (const i in data) {
          if (data[i]) formated_data[i] = data[i];
        }
        createImage(formated_data).then((response_gpt) => {
          if (response_gpt.status >= 400) {
            this.loader_image_search = false;
            if (response_gpt.data.response.code === 'prompt.NotFriendly') {
              this.show_modal_not_friendly = true;
              this.update_modal_not_friendly = !this.update_modal_not_friendly;
              this.setting_selected.promp_ai = null;
            }

            loadToastificationContent().then((module) => {
              this.$toast({
                component: module.default,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: this.$t('search.mesageError'),
                },
              });
            })
          }
        })
      } else if (this.getCredits() >= 0 && this.setting_selected.file_variants) {
        this.loader_image_search = true
        const data = new FormData();
        data.append('model', this.setting_selected.ia_selected.value)
        data.append('prompt', this.setting_selected.promp_ai);
        data.append('number', this.setting_selected.number_image_ai);
        data.append('width', this.setting_selected.size_images_selected.value_width);
        data.append('height', this.setting_selected.size_images_selected.value_height);
        data.append('image', this.setting_selected.file_variants);

        createVariationsImage(data).then((response) => {
          if (response.status >= 400) {
            this.loader_image_search = false;

            loadToastificationContent().then((module) => {
              this.$toast({
                component: module.default,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: this.$t('search.mesageError'),
                },
              });
            })
          }
        });
      } else this.$emit('show_modal_update_suscription')
    },
    backToHistory() {
      this.$router.push({ name: 'brandme_AI', params: { section: 'histories', category: 'image'}});
      this.$emit('back_histories')
    },
    gridColSpan(index) {
      if (this.index_ratio_selected !== index) {
        this.data_img_ai.forEach((_, inner_index) => {
          this.$refs[`aspect_ratio_box_${inner_index}`][0].classList.remove('grid-col-span')
        })
      }
      this.index_ratio_selected = index;
      this.$refs[`aspect_ratio_box_${index}`][0].classList.toggle('grid-col-span')
    },
    clearAspectsRatio() {
      const images =  this.data_img_ai.length > 0 ? this.data_img_ai.length : this.setting_selected.number_image_ai
      for (let index = 0; index < images; index++) {
        const grid_container = this.$refs[`aspect_ratio_box_${index}`]
        if (grid_container) {
          grid_container[0].classList.forEach((item) => {            
            if (item.includes('aspect-ratio')) grid_container[0].classList.remove(item);
          })
          grid_container[0].classList.add(`aspect-ratio-1-1`); 
        }
      }
    },
    setAspectRatio(val, add_aspect_one = true) {
      if (val) {
        const aspects = val.split(':');
        const images =  this.data_img_ai.length > 0 ? this.data_img_ai.length : this.setting_selected.number_image_ai

        for (let index = 0; index < images; index++) {
          const grid_container = this.$refs[`aspect_ratio_box_${index}`]
          if (grid_container) {
            grid_container[0].classList.forEach((item) => {
              if (item.includes('aspect-ratio')) grid_container[0].classList.remove(item);
            })
            if (add_aspect_one) grid_container[0].classList.add(`aspect-ratio-${aspects[0]}-${aspects[1]}`, 'aspect-one')
            else grid_container[0].classList.add(`aspect-ratio-${aspects[0]}-${aspects[1]}`)
          }
        }
      }
    }
  },
  watch: {
    'setting_selected.ia_selected'(val) {
      if (val.value === 'imagine') this.setting_selected.number_image_ai = 1
      else {
        this.clearAspectsRatio();
      }
    },
    'setting_selected.number_image_ai'(val) {
      const original_size = this.setting_selected.size_images_selected
      const size = `${original_size.value_height}:${original_size.value_width}`
      this.setAspectRatio(size, false);
      if (this.data_img_ai.length > 0) {
        this.data_img_ai = this.request_history.response_server.data.slice(0, val)
      }
    },
    'setting_selected.size_images_selected'(val) {
      const size = `${val.value_height}:${val.value_width}`
      this.setAspectRatio(size, false);
    },
    'options_imagine.aspect_ratio'(val) {
      this.setAspectRatio(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.aspect-one {
  grid-template-columns: repeat(1, 1fr);
  display: flex;
  justify-content: center;
}
.aspect-ratio-1-1 {
  .aspect-ratio {
    aspect-ratio: 1 / 1;
  };
}
.aspect-ratio-3-2 {
  .aspect-ratio {
    aspect-ratio: 3 / 2;
  };
}
.aspect-ratio-4-3 {
  .aspect-ratio {
    aspect-ratio: 4 / 3;
  };
}
.aspect-ratio-3-4 {
  .aspect-ratio {
    aspect-ratio: 3 / 4;
  };
}
.aspect-ratio-16-9 {
  .aspect-ratio {
    aspect-ratio: 16 / 9;
  };
}
.aspect-ratio-9-16 {
  .aspect-ratio {
    aspect-ratio: 9 / 16;
  };
}
.aspect-ratio-1152-896 {
  .aspect-ratio {
    aspect-ratio: 1152 / 896;
  };
}
.aspect-ratio-896-1152 {
  .aspect-ratio {
    aspect-ratio: 896 / 1152;
  };
}

.aspect-ratio-1216-832 {
  .aspect-ratio {
    aspect-ratio: 1216 / 832;
  };
}

.aspect-ratio-832-1216 {
  .aspect-ratio {
    aspect-ratio: 832 / 1216;
  };
}

.aspect-ratio-1344-768 {
  .aspect-ratio {
    aspect-ratio: 1344 / 768;
  };
}

.aspect-ratio-768-1344 {
  .aspect-ratio {
    aspect-ratio: 768 / 1344;
  };
}

.aspect-ratio-1536-640 {
  .aspect-ratio {
    aspect-ratio: 1536 / 640;
  };
}

.aspect-ratio-640-1536 {
  .aspect-ratio {
    aspect-ratio: 640 / 1536;
  };
}
.size-input::placeholder {
  color: #70747c;
}
.width-right-area {
  width: 420px;
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.size-button-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 3rem;
}
.m-5rem{
  margin-left: 0.5rem;
}
.body-button-center{
  position: relative;
  background-color: #e9ecef;
  padding: .25rem .25rem;
  border-radius: .375rem;
}
.active-button-center{
  background-color: #fff !important;
  border-color: #fff !important;
  border-radius: 10% !important;
  box-shadow: 0rem 0.1875rem 0.375rem 0rem rgba(108,117,125,.25);
}
.color-btn-center{
  background-color: #e9ecef !important;
  border-color: #e9ecef !important;
  box-shadow: none !important;
}
.size-drop-responsive{
  display: none;
}
.icon-setting-sider{
  background-color: #e9ecef;
  display: none;
}
.background-input-botton{
  width: 100%;
  padding: 1em 0 0 0;
}
.div-input-button{
  backdrop-filter: blur(2px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 0.5em;
  .badge-button-ia {
    display: flex;
    align-items: center;
    margin-left: 0.5em;
    line-height: 10px;
    background-color: #40736E;
    .icon-ia {
      width: 14px;
      height: 14px;
      margin-right: 0.2em;
    }
  }
}
.size-input{
  background-color: transparent;
  height: 100%;
  width: 80%;
  font-size: 15px;
  font-weight: bold;
  border: none;
  box-shadow: none !important;
  font-family: monospace !important;

  @media(max-width: 500px) {
    font-size: 14px;
  }
}
.generate-img-ai{
  height: 42px;
  display: flex;
  align-items: center;
  background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
}
.grid-container {
  display: grid;
  grid-gap: 14px;
  grid-auto-flow: dense;
  .grid-col-span {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
}
.grid-long-left {
  grid-template-columns: repeat(8, 1fr);
}
.grid-active-left {
  grid-template-columns: repeat(4, 1fr);
}
.grid-desactive-left {
  grid-template-columns: repeat(5, 1fr);
}
.grid-one {
  grid-template-columns: repeat(1, 1fr);
  display: flex;
  aspect-ratio: 2 / 1;
  max-height: 500px;
  justify-content: center;
}

.aspect-ratio {
  background: linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(219,219,219,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  .skeleton-loading-ia {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .img-stars {
    width: 25%;
    height: 25%;
    color: #d3d3d3;
  }
  .img-ia {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container-ia-svg {
    position: absolute;
    width: 5%;
    height: 5%;
    right: 5%;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ia-svg-icon {
      width: 170%;
      height: 170%;
    }
  }
}
.container-dropdown-image {
  background-color: rgba(0, 0, 0, 0.699);
  position: absolute;
  top: 5%;
  border-radius: 0.5em;
  right: 5%;
  padding: 0.5em;
  color: white;
  cursor: pointer;
  transform: translateX(100px);
}
.aspect-container-ratio {
  overflow: hidden;
  &:hover {
    .container-dropdown-image {
      animation: dropdown-animation 300ms forwards;
    }
  }
  &:not(:hover) { 
    .container-dropdown-image {
      animation: dropdown-animation-back 300ms forwards;
    }
  }
}
@media (max-width: 1199px) {
  .icon-setting-sider{
    display: flex;
  }
  .width-right-area {
    display: none;
  }
}
@media (max-width: 1000px) {
  .grid-active-left {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-desactive-left {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-long-left {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (max-width: 770px) {
  .max-width-disabled{
    display: none;
  }
  .size-drop-responsive{
    display: flex;
  }
}
@media (max-width: 765px) {
  .grid-active-left, .grid-desactive-left {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-long-left {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 515px) {
  .grid-active-left, .grid-desactive-left {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-long-left {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 350px) {
  .grid-long-left {
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes dropdown-animation {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes dropdown-animation-back {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
</style>
<style lang="scss">
.sidebar-right-responsive-options-models-ia .b-sidebar {
  width: 400px;
}
.dropdown-options-image .btn {
  padding: 0.5em !important;
}
</style>